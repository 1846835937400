/* Global Styles */
/* :root {
  --dark-color: #333333;
  --light-color: #f4f4f4;
} */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* width : 100%;
  height: 100vh; */
}

.main-wrapper {
  padding: 25px 30px 200px;
}

.btn-xs {
  padding: 0 0.5rem;
  font-size: 0.6rem;
  line-height: 1.0rem;
}